import React, {lazy} from "react";
// import Signin from "../pages/users/login/Signin";

const Signin = lazy(() => import('../pages/users/login/Signin'));

const publicRoutes = [
  { path: "users/signin", element: <Signin /> },

];

export default publicRoutes;