import React, { Suspense, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary'

import protectedRoutes from "./routes/ProtectedRoutes";
import publicRoutes from "./routes/PublicRoutes";
import errorRoutes from "./routes/ErrorRoutes";

import Loading from './components/loading/Loading';

// import Main from './layouts/Main';

// import NotFound from "./pages/error/NotFound";
// import InternalServerError from "./pages/error/InternalServerError";


const Main = lazy(() => import('./layouts/Main'));
const NotFound = lazy(() => import('./pages/error/NotFound'));
const InternalServerError = lazy(() => import('./pages/error/InternalServerError'));


const AppRoutes = () => {
  const user = useSelector((state) => state.user);
  return (
    <Suspense fallback={<Loading/>}>
      <Routes>
        <Route path={process.env.REACT_APP_DEFAULT_URL} element={user.isLogin ? <Main /> : <Navigate to={process.env.REACT_APP_LOGIN_URL} />}>
          {protectedRoutes.map((route, index) => {
            return (
              <Route
                path={route.path}
                element={
                  <ErrorBoundary key={index} FallbackComponent={InternalServerError} >
                    {user.isLogin ? (
                      route.role == null ? (
                        route.element
                      ) : (
                        route.role === user.role ? (
                          route.element
                        ) : (
                          <Navigate to={process.env.REACT_APP_DEFAULT_URL} />
                        )
                      )
                    ) : (
                      <Navigate to={process.env.REACT_APP_LOGIN_URL} />
                    )}
                  </ErrorBoundary>
                }
                key={index}

              />
            )
          })}
        </Route>
        {publicRoutes.map((route, index) => {
          return (
            <Route
              path={route.path}
              element={user.isLogin ? <Navigate to={process.env.REACT_APP_DEFAULT_URL} /> : route.element}
              key={index}
            />
          )
        })}
        {errorRoutes.map((route, index) => {
          return (
            <Route
              path={route.path}
              element={route.element}
              key={index}
            />
          )
        })}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
