import React, { useEffect, useState } from "react";
import { ClipLoader } from 'react-spinners';

export default function Loading() {

  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);
  const [color, setColor] = useState('');

  const switchSkin = (skin) => {
    if (skin === 'dark') {
        setColor('#ffffff');
    } else {
      setColor('');
    }

  }

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (

    <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
      <ClipLoader color={color} />
    </div>
  )
}
