import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //StrictMode 썼더니 렌더링? 될 때 두번 실행 되면서 SpreadJs 설정한 부분에 스크립트 오류나서 제거함 */}
  // <React.StrictMode>
  <React.Fragment>
    <App />
    <div className="main-backdrop"></div>
  </React.Fragment>
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
