import React, {lazy} from "react";

// Dashboard
const OverView = lazy(() => import('../pages/dashboard/OverView'));
const Settings = lazy(() => import('../pages/users/settings/Settings'));
const HIW = lazy(() => import('../pages/environment/HIW'));
const HIS = lazy(() => import('../pages/environment/HIS'));
const TI = lazy(() => import('../pages/environment/TI'));
const WI = lazy(() => import('../pages/environment/WI'));
const AccessLog = lazy(() => import('../pages/admin/AccessLog'));
const JobSharingBoard = lazy(() => import('../pages/reports/JobSharingBoard'));
const ReportList = lazy(() => import('../pages/reports/ReportList'));
const ReportWrite = lazy(() => import('../pages/reports/ReportWrite'));
const ReportWeeklyView = lazy(() => import('../pages/reports/ReportWeeklyView'));
const ReportWeeklyARView = lazy(() => import('../pages/reports/ReportWeeklyARView'));
// import OverView from "../pages/dashboard/OverView";

// // Pages
// import Settings from "../pages/users/settings/Settings";

// import HIW from "../pages/environment/HIW";
// import HIS from "../pages/environment/HIS";
// import TI from "../pages/environment/TI";
// import WI from "../pages/environment/WI";

// //ad
// import AccessLog from "../pages/admin/AccessLog";

// import JobSharingBoard from "../pages/reports/JobSharingBoard"

// // Reports
// import ReportList from "../pages/reports/ReportList";
// import ReportWrite from "../pages/reports/ReportWrite";
// import ReportWeeklyView from "../pages/reports/ReportWeeklyView";
// import ReportWeeklyARView from "../pages/reports/ReportWeeklyARView";



const protectedRoutes = [
  { path: "/", element: <OverView /> },
  { path: "dashboard/overview", element: <OverView /> },
  
  { path: "environmental/hiw", element: <HIW /> },
  { path: "environmental/his", element: <HIS /> },
  { path: "environmental/ti", element: <TI /> },
  { path: "environmental/wi", element: <WI /> },

  { path: "admin/access-log", element: <AccessLog />, role: 'ADMIN' },

  { path: "users/settings", element: <Settings /> },
 
  { path: "reports/list", element: <ReportList /> },
  { path: "reports/write", element: <ReportWrite /> },
  { path: "reports/reportWeeklyView", element: <ReportWeeklyView /> },
  { path: "reports/reportWeeklyARView", element: <ReportWeeklyARView /> },
  { path: "reports/job-sharing-board", element: <JobSharingBoard />},
]

export default protectedRoutes;