import React from "react";
import { BrowserRouter } from "react-router-dom";
import Routers from "./Routers";

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

// import css
import "./assets/css/remixicon.css";

// import scss
import "./scss/style.scss";

import store from './store/index';

// set skin on load
window.addEventListener("load", function () {
  let skinMode = localStorage.getItem("skin-mode");
  let HTMLTag = document.querySelector("html");

  if (skinMode) {
    HTMLTag.setAttribute("data-skin", skinMode);
  }
});

export let persistor = persistStore(store);


export default function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Routers/>
          </PersistGate>
        </Provider>
      </BrowserRouter>
    </React.Fragment>

  );
}