import { createSlice } from "@reduxjs/toolkit";

// state의 초기값 (유저 정보)
export const initialState = {
    seq: 0,
    id: "admin2",
    pw: "",
    name: "",
    email: "",
    role: "ADMIN",
    status: "A",
    regAt: "2024-05-27 10:40:01",
    lastLoginAt: "2024-08-05 14:42:32",
    accessToken: "",
    isLogin: true
};

const UserSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action) => {
            // Object.assign을 사용하여 상태를 병합
            Object.assign(state, action.payload);
            // 로그인 정보 있으면 무조건 isLogin true
            state.isLogin = true;
        },
        setIsLogin: (state, action) => { // 액션 타입 2
            state.isLogin = action.payload;
        },
        setAccessToken: (state, action) => {
            state.accessToken = action.payload;
        },
        resetUser: state => initialState, // 리셋 액션에 대한 리듀서
    },
});

// actions
// dispatch로 액션을 전달해 상태를 어떻게 변화시킬지를 결정함
export const { setUser, setIsLogin, setAccessToken, resetUser } = UserSlice.actions;
export default UserSlice;