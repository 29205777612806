import React, {lazy} from "react";
// import InternalServerError from "../pages/error/InternalServerError";
// import NotFound from "../pages/error/NotFound";

const InternalServerError = lazy(() => import('../pages/error/InternalServerError'));
const NotFound = lazy(() => import('../pages/error/NotFound'));

const errorRoutes = [
  { path: "pages/error-404", element: <NotFound /> },
  { path: "pages/error-500", element: <InternalServerError /> }
];

export default errorRoutes;