import {configureStore} from '@reduxjs/toolkit';
import UserSlice from '../components/users/UserContext';
import storage from 'redux-persist/lib/storage';
import {combineReducers} from 'redux';
import {persistReducer} from 'redux-persist';

const reducers = combineReducers({

    user : UserSlice.reducer
});

const persistConfig = {
    key : 'root',
    storage,
    whitelist : ['user']
};

const persistedReducer = persistReducer(persistConfig , reducers);

const store = configureStore({
    reducer : persistedReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }),
})

//전체 스토어의 상태 타입
//export type RootState = ReturnTvype<typeof store.getState>;

export default store;